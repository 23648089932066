<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2023-01-16 21:31:49
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 充值
 * @FilePath: /java-pc/src/views/member/money/recharge.vue
-->
<template>
    <div class="sld_AccountNow">
        <MemberTitle memberTitle="开通钱包"></MemberTitle>
        <div class="sld_bankCard_con">
            <div class="bank_top">
                <div>开通钱包</div>
            </div>
            <div class="bank_center">
                <div class="bank_state">
                    <div class="step">
                        <span class="line"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng"></i>
                        <span>1.身份验证</span>
                    </div>
                    <div class="step">
                        <span :class="[isidentity == 1 || isidentity == 3 || isidentity == 2? 'line' : 'no_line']"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng" :class="[isidentity == 1 || isidentity == 3 || isidentity == 2? '' : 'no_sel']"></i>
                        <span>2.开通钱包绑卡</span>
                    </div>
                    <div class="step">
                        <span :class="[isidentity == 2? 'line' : 'no_line']"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng" :class="[isidentity == 2? '' : 'no_sel']"></i>
                        <span>3.验证码验证</span>
                    </div>
                </div>
            </div>
            <div class="bank_bottom_Form">
                <!-- 打款验证 end -->
                <el-form :model="bankForm" label-width="120px" v-show="isidentity == 1">
                    <el-form-item label="银行名称:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.acctOpenBranchName" placeholder="请输入银行名称" :suffix-icon="isarrow?'el-icon-arrow-down':'el-icon-arrow-up'" @focus="inputFocus"
                                @blur="inputBlur" @input="inputName" ref="inputRef" />
                            <div class="search"  v-if="isFocus" style="z-index: 999;">
                                <div class="search_dialog">
                                    <div class="scrollbar-demo-item" v-for="item in bankList" :key="item.bankCode"
                                        @mousedown="selectName(item)">
                                        {{ item.bankName }}</div>
                                </div>
                                
                                <!-- <el-scrollbar max-height="150px" class="search_dialog" > -->
                                    <!-- <ul class="search_dialog"> -->
                                    
                                    <!-- </ul> -->
                                <!-- </el-scrollbar> -->
                            </div>
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="银行类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.bankType" placeholder="港澳回乡证">
                                <el-option label="本行" value="1" />
                                <el-option label="外行" value="2" />
                            </el-select>
                        </el-col>
                    </el-form-item> -->
                    <el-form-item label="银行卡号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.bankCardNo" type="number" minlength="8" maxlength="30" placeholder="请输入银行卡号" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.mobile" maxlength="11" placeholder="请输入手机号" />
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="证件号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.reprGlobalId" placeholder="请输入证件号" />
                        </el-col>
                    </el-form-item> -->

                    <!-- <el-form-item label="法人证件类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.reprGlobalType" placeholder="港澳回乡证">
                                <el-option label="身份证" value="1" />
                                <el-option label="台胞证" value="5" />
                                <el-option label="中国护照" value="4" />
                                <el-option label="外国护照" value="19" />
                            </el-select>
                        </el-col>
                    </el-form-item> -->
                    <div class="next">
                        <div @click="next(2)">下一步</div>
                    </div>
                </el-form>
                <!-- <el-form :model="bankForm" label-width="120px" v-if="isidentity == 2"> -->
                <el-form :model="bankForm" label-width="120px" v-if="isidentity == 2">
                    <el-form-item label="验证码">
                        <el-col :span="8" style="float: none;">
                            <el-input v-model="testPhoneNum" placeholder="请输入验证码" width="120px" />
                            <div class="timeOut" @click="getTestNum">{{ descNum ? (descNum+'s后获取') : '获取验证码'  }}</div>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <div>{{bankForm.mobile}}</div>
                    </el-form-item>
                    <div class="next2">
                        <div @click="sureSub">确认提交</div>
                    </div>
                </el-form>
                <!-- <div class="next2">
                        <div @click="next(3)">确认提交</div>
                    </div> -->
                <!-- </el-form> -->
            </div>
            <!-- <div class="bank_bottom_Form1" v-else>
                <img src="../../../assets/member/empty_goods.png" />
                <div class="info">验证失败</div>
                <div class="info">失败原因： 失败失败</div>
                <div class="next">
                    <div @click="next(2)">重新申请</div>
                </div>
            </div> -->
        </div>
    </div>
</template>
  
<script>
import { ElInput, ElButton, ElMessage, ElScrollbar } from "element-plus";
import MemberTitle from '../../../components/MemberTitle';
import { useRouter,useRoute } from 'vue-router';
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex'
// import { Store } from "vuex";
export default {
    name: "AccountNow2",
    components: {
        MemberTitle
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const isarrow = ref(true);
        // 银行表单
        // 银行列表
        const bankList = ref({});
        const route = useRoute();
        const getBankList = () => {
            proxy.$get('api/pay/common/queryBankOrg').then(res => {
                if (res.code == 200) {
                    setTimeout(()=>{

                        bankList.value = res.data
                    })
                    // console.log(bankList.value)
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        getBankList()
        //  检索银行列表
        // 防抖函数
        const inputName = () => {
            // 防抖
            let timer
            if (timer) {
                clearTimeout(timer)
                timer = null
            }
            timer = setTimeout(() => {
                console.log(1)
                proxy.$get('api/pay/common/queryBankOrg', { bankName: bankForm.value.acctOpenBranchName }).then(res => {
                    if (res.code == 200) {
                        // ElMessage.success(res.msg)
                        bankList.value = res.data
                    } else {
                        ElMessage.warning(res.msg)
                    }
                })
                clearTimeout(timer)
                timer = null
            }, 1000)
        }
        let inputRef = ref(null)
        const selectName = (item) => {
            bankForm.value.acctOpenBranchName = item.bankName
            bankForm.value.is_show = item.bankName
            bankForm.value.bankCode = item.bankCode
            isFocus.value = false
        }
        const bankForm = ref({
            acctOpenBranchName: '',
            bankCardNo: '',
            mobile: '',
            bankType: '',
            reprGlobalType: '',
            bankCode: '',
            reprGlobalId: '',
            is_show:''
        })
        let isidentity = ref(1) //判断绑定页面显示隐藏
        const router = useRouter()
        const next = (num) => {
            if(num==2){
                if(!bankForm.value.acctOpenBranchName.trim()){
                    ElMessage.warning('请选择银行名称')
                    return
                }
                if(bankForm.value.acctOpenBranchName!=bankForm.value.is_show){
                    ElMessage.warning('请选择正确的银行名称')
                    return
                }
                if(!bankForm.value.bankCardNo.trim() || bankForm.value.bankCardNo.length<8){
                    ElMessage.warning('请输入正确的银行卡号')
                    return
                }
                if(!bankForm.value.mobile.trim()){
                    ElMessage.warning('请输入手机号')
                    return
                }
            }
            isidentity.value = num
        }
        const onSubmit = () => {
            console.log('submit!')
        }
        // 获取银行列表
        const isFocus = ref(false)
        const inputFocus = () => {
            isFocus.value = true;
            isarrow.value = false
        }
        // 失去焦点
        const inputBlur = () => {
            isFocus.value = false;
            isarrow.value = true
        }

        // 获取验证码
        const testPhoneNum = ref()
        const descNum = ref(0)
        let testCardId = ref()
        let isFlag = ref(false)
        const getTestNum = () => {
            if (isFlag.value == true) {
                return
            } else {
                if(descNum.value){
                    return
                }
                // isFlag.value = truenterval(timedesc)
                // }, 6000)
                const memberInfo = JSON.parse(localStorage.getItem('memberInfo'))
                let params = {}
                if(store.state.memberInfo.memberUserType==2){
                     params.userId=store.state.memberInfo.memberUserType==2?store.state.memberInfo.uscUserPid:store.state.memberInfo.uscUserId
                    params.userMobile = bankForm.value.mobile
                    params.firmBankName = bankForm.value.acctOpenBranchName
                    params.firmBankAccount = bankForm.value.bankCardNo
                    //params.openingBank=bankForm.value.acctOpenBranchName
                    params.isFirm= false
                }else{
                    params.userId=store.state.memberInfo.memberUserType==2?store.state.memberInfo.uscUserPid:store.state.memberInfo.uscUserId
                    params.userMobile = bankForm.value.mobile
                    params.firmBankName = bankForm.value.acctOpenBranchName
                    params.firmBankAccount = bankForm.value.bankCardNo
                    //params.openingBank=bankForm.value.acctOpenBranchName
                    //corporateCode: 
                    //corporateName:demoData
                }
               
                //if(store.state.memberInfo.memberUserType==2){

                //}
                // params.bankType = bankForm.value.bankType
                // params.reprGlobalType = bankForm.value.reprGlobalType
                // params.reprGlobalId =  bankForm.value.reprGlobalId
                params.enterpriseLegalName = JSON.parse(localStorage.getItem('memberInfo')).memberTrueName
                proxy.$post('api/pay/wallet/authentication/number', JSON.stringify(params), "json").then(res => {
                    if (res.code == 200) {
                        ElMessage.success(res.msg)
                        descNum.value = 120
                        isidentity.value = 2
                        isFlag.value = false
                        testCardId.value = res.cardId
                        countDown()
                    } else {
                        ElMessage.warning(res.msg)
                        isFlag.value = false
                    }
                })
            }

        }
        const timeOutId = ref(""); //定时器的返回值
         //倒计时
        const countDown = () => {
            descNum.value--;
            if (descNum.value == 0) {
                isFlag.value = false
            clearTimeout(timeOutId.value);
            } else {
            timeOutId.value = setTimeout(countDown, 1000);
            }
        };
        // 获取验证码end
        // 验证验证码
        const store = useStore()
        const sureSub = () => {
            let params = {}
            // console.log(userType)
            // params.state = memberInfo.userType
            // params = {}
            params.firmBankName = bankForm.value.acctOpenBranchName
            params.userMobile = bankForm.value.mobile
            //params.acctOpenBranchName = bankForm.value.acctOpenBranchName
            params.firmBankAccount = bankForm.value.bankCardNo
             params.bankType = bankForm.value.bankType
             params.reprGlobalType = bankForm.value.reprGlobalType
             params.reprGlobalId = JSON.parse(localStorage.getItem('memberInfo')).memberCardNo
             params.enterpriseLegalName = JSON.parse(localStorage.getItem('memberInfo')).memberTrueName
            params.authenticationNumber = testPhoneNum.value
            console.log(params);
             let params1 = JSON.stringify(params)
            proxy.$post('api/pay/wallet/bank/card/insert', JSON.stringify(params), "json").then(res => {
                if (res.code == 200) {
                    ElMessage.success(res.msg)
                    console.log(res)
                    // testPhoneNum.value = res.data
                    if (store.state.memberInfo.hasPayPassword = 1) {
                    router.push('/member/pwd/reset')
                    } else {
                        router.push('/member/balance')
                    }

                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        // const getInfo=()=>{
        //     proxy.$get('/v3/member/front/member/getInfo').then(res => {
        //         if (res.state == 200) {
        //             bankForm.value.mobile = res.data.memberMobile
        //         }
        //     })
        // }
        onMounted(() => {
        // getInfo()
        if(route.query.mobile){
            bankForm.value.mobile = route.query.mobile

        }
        });
        return {
            isarrow,
            isidentity,
            next,
            onSubmit,
            bankForm,
            inputFocus,
            isFocus,
            // getInfo,
            inputBlur,
            getTestNum,
            isFlag,
            descNum,
            bankList,
            inputName,
            selectName,
            inputRef,
            sureSub,
            testPhoneNum,
            countDown,
            timeOutId
        };
    }
};
</script>
  
<style lang="scss">
.bank_bottom_Form {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    }
    input[type='number'] {
    -moz-appearance: textfield;

    }
    .el-input__inner{
    line-height: 1px !important;
    }
    .el-cascader {
        width: 100%;
    }

    .el-input__inner {
        height: 31px;
    }

    .el-form-item {
        margin-bottom: 10px;

        .el-select {
            width: 100%;
        }
    }
}
.sld_AccountNow{
    .search_dialog::-webkit-scrollbar {
        width: 10px;
    }
    .search_dialog::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        opacity: 0.2;
        background: #909399;
    }
    .search_dialog::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        // background: #909399;
    }
    .search_dialog {
        // position: absolute;
        background-color: white;
        z-index: 9999;
        min-width: 335.324px;
        max-height: 150px;
        color: var(--el-text-color-regular);
        border-radius: var(--el-border-radius-base);
        background: white;
        padding: 6px 0;
        box-shadow: var(--el-box-shadow-light);
        box-sizing: border-box;
        overflow: auto;
         

        div {
            width: 100%;
            height: 34px;
            line-height: 34px;
            cursor: pointer;
            padding-left: 20px;

            &:hover {
                background-color: var(--el-background-color-base);

            }
        }
    }
}


</style>
<style lang="scss" scoped>
.scrollbar-demo-item {
    height: 50px;
    text-align: left;
    border-radius: 4px;
    color: var(--el-color-primary);
    width: 100%;
}



.search {
    // position: relative;
    position: absolute;

    
}

.bank_bottom_Form {
    margin-left: 80px;
    // margin-top: 40px;
    position: relative;

    .timeOut {
        position: absolute;
        top: 5px;
        right: 390px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: $colorMain2;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
    }

    .next2 {
        width: 70px;
        background-color: $colorMain;
        height: 30px;
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        margin: 0 auto;
        position: absolute;
        left: 120px;
        top: 100px;
        cursor: pointer;
    }

    .next {
        width: 325px;
        background-color: $colorMain;
        height: 31px;
        border-radius: 5px;
        text-align: center;
        line-height: 31px;
        color: #fff;
        // margin: 0 auto;
        margin-top: 80px;
        margin-left: 120px;
        cursor: pointer;
    }
}

.bank_bottom_Form1 {
    margin-top: 20px;
    margin-left: 100px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;

    .info {
        font-size: 16px;
        margin-top: 20px;
    }

    .next {
        border: 1px solid rgba(187, 187, 187, 1);
        width: 110px;
        height: 50px;
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 40px;
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
    }
}

.sld_AccountNow {
    width: 1020px;
    float: left;
    margin-left: 10px;

    .unbinding {
        height: 40px;
        line-height: 40px;
        text-align: right;
        width: 307px;
        color: rgba(30, 119, 240, 1);
        cursor: pointer;
    }

    .sld_bankCard_con {
        background: #fff;
        height: 790px;
        // padding-bottom: 50px;
        padding: 0 20px;

        .bank_top {
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                &:nth-child(1) {
                    line-height: 70px;
                    color: #1C1C1C;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                }
            }
        }
    }
}

.bank_center {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    .bank_state {
        display: flex;
        align-items: center;
        .step {
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 28px;
            background-size: 100% 100%;
            // color: white;
            font-size: 14px;
            margin-right: 20px;

            &:nth-last-of-type(1) {
                margin-right: 0;
            }

            .line {
                width: 70px;
                height: 1px;
                background: #51b953;
                display: inline-block;
            }

            .no_line {
                width: 70px;
                height: 1px;
                display: inline-block;
                background: #eeeeee;
            }

            .sel_line {
                background: #51b953;
            }

            i {
                font-size: 16px;
                color: #33ad36;
                margin: 0 10px 0 20px;
            }

            .no_sel {
                color: #e2e2e2;
            }

            .no_step_text {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            .step_text {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
        }
    }

    .red_state {
        background-color: $colorMain;
        color: rgba(255, 255, 255, 1);
    }

    .white_state {
        position: relative;
        width: 103px;
        height: 103px;
        line-height: 103px;
        // background-color: rgba(240, 39, 39, 1);
        // color: rgba(255, 255, 255, 1);
        // color: black;
        font-size: 20px;
        text-align: center;
        font-family: Roboto;
        border-radius: 50%;
        border: 1px solid rgba(187, 187, 187, 1);

        span {
            position: absolute;
            left: 50%;
            bottom: -50px;
            transform: translate(-50%, -50%);
            line-height: 20px;
            font-size: 14px;
            color: black;
            width: 100px;
        }
    }


}
</style>